
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css'; // Use this CSS module
import styles from './editable-grid.module.scss';


export function DatePickerGrid({
    row,
    column,
    onRowChange,
    onClose }) {
     //   const [Dates, setDates] = useState([]);
        console.log(row[column.key])
        const isDate = (new Date(row[column.key]) !== "Invalid Date") && !isNaN(new Date(row[column.key]));
        //    let Dates =  (isDate) ? new Date(row[column.key]) : new Date();
           let Dates =  (isDate) ? new Date(row[column.key]).getTime() : new Date().getTime();
        
           const handleFieldChangeKeyValue = (date) => {
            // onRowChange({ ...row, [column.key]: new Date(date)});
            onRowChange({ ...row, [column.key]: new Date(date).getTime(date) });
          }
        return (
            <DatePicker 
                className={styles.partnerDatepicker}
                selected={Dates || null}
                value={Dates}
                onChange={(date) => handleFieldChangeKeyValue(date)}
                dateFormat="dd/MM/yyyy"
                isClearable
                autoFocus 
            />
        );
    };
