import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./cmsdashboard.scss";
import floderEmpty from "../../assets/images/floder-empty.png";
import icroundsearch from "../../assets/images/ic_round-search.svg";
import floderaddicon from "../../assets/images/floder-add-icon.svg";
import floderremoveicon from "../../assets/images/floder-remove-icon.svg";
import viewlist from "../../assets/images/view_list.svg";
import view_module from "../../assets/images/view_module.svg";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import rightarrow from "../../assets/images/rightvector.png";
import leftarrow from "../../assets/images/Vector.png";
import editicon from "../../assets/images/editicon.png";
import viewIcon from '../../assets/images/carbon_view.svg';
import archiveicon from "../../assets/images/archiveicon.png";
import deleteicon from "../../assets/images/delete_forever.png";
import assetpart from "../../assets/images/assetpart.png";
import AddPartnerpopup from "../../component/popup/AddPartnerpopup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Asset_Detail,
  Partner_archive,
  Partner_delete,
  Partner_list,
  Partner_search,
  ProgramsList_By_Partner,
} from "../../api/api";
import filter_alt from "../../assets/images/sortarrow.png";
import desc from "../../assets/images/desc.png";
import { useDispatch } from "react-redux";
import { AssetDetailAction } from "../../Redux/slices";
import { DateStamp, isLogin } from "../../service/commonFunc";
import ErrorPopup from "../../component/popup/ErrorPopup";
import Loader from "../../component/Loader/Loader";
import Filler from "./channeldetail/Filler";
import MyModal from "../../component/filemanager/filemanager";
import { permissionDetails } from "../../utility/localStoageData";


function AssetManagement() {
  const navigate = useNavigate();

  const [activeView, setActiveView] = useState("grid");
  const [PartnerData, setPartnerData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [createEdit, setCreateEdit] = useState("create");
  const [singledata, setSingleData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [permission, setpermission] = useState(null);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);
  const handleViewChange = (view) => {
    setActiveView(view);
  };

  const getPermission = () => {
    let findEdit = permissionDetails();
    if (findEdit.includes("ASSET_MANAGEMENT_EDIT")) setpermission("edit");
    else if (findEdit.includes("ASSET_MANAGEMENT_READ")) setpermission("read");
    else setpermission(null);
  }

  useEffect(() => {
    isLogin();
    partnerListDetail();
    getPermission();
  }, []);
  const partnerListDetail = async () => {
    const partnerData = await Partner_list();

    if (partnerData?.data && partnerData?.status === true) {
      setPartnerData(partnerData?.data.content);
    }
  };

  const partnerSearch = async (searchQuery) => {
    const partnerDataSearch = await Partner_search(searchQuery);

    if (partnerDataSearch?.data && partnerDataSearch?.status == true) {
      setPartnerData(partnerDataSearch?.data);
    }
  };

  // List view
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = PartnerData?.slice(startIndex, endIndex);

  const totalPages = Math.ceil(PartnerData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  //card

  // Create an array to store open states for each card
  const [isDropdownOpenArray, setIsDropdownOpenArray] = useState(
    new Array(PartnerData.length).fill(false)
  );
  const handleDropdownSelect = (selectedOption, index) => {
    // Implement the action for the selected option here
    // Close the dropdown for the selected card
    setIsDropdownOpenArray((prevState) =>
      prevState.map((value, i) => (i === index ? false : value))
    );
  };

  //add partner pop

  const openCreatePartnerPopup = () => {
    setCreateEdit("create");
    setShowPopup(true);
  };

  const openEditPartnerPopup = (partner) => {
    setCreateEdit("edit");
    setSingleData(partner);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setCreateEdit("create"); // Reset to create mode
    setSingleData(null); // Clear the single data
    partnerListDetail();
  };

  // search
  const handleSearch = () => {
    // Call your search API with the searchQuery value
    // You can use libraries like Axios or fetch for making the API request
    // For this example, we'll use a simple console.log to demonstrate
    // console.log("Searching for:", searchQuery);
    partnerSearch(searchQuery);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      partnerSearch(searchQuery);
    }
  };

  const partnerDelete = async (data) => {
    const partnerDelete = await Partner_delete(data);

    if (partnerDelete?.data?.status == true) {
      partnerListDetail();
      toast.success("Content Partner deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error(
        partnerDelete?.message ||
        "Something went wrong, please try after sometime",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        }
      );
    }
  };
  const partnerArchive = async (data) => {
    const Partnerarchive = await Partner_archive(data);
    if (Partnerarchive.data.status == true) {
      toast.success("Content Partner archived successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };
  // const dispatch = useDispatch();

  const partnerdetail = async (assetdetails) => {
    // console.log("asdfghjkl",assetdetail);
    const params = {
      partnerId: assetdetails.id,
      pageSize: "1000",
    };

    const AssetDetail = await ProgramsList_By_Partner(params);

    if (AssetDetail.status == true) {
      // console.log(AssetDetail.status == true);
      localStorage.setItem("AssetDetail", JSON.stringify(AssetDetail?.data));
      localStorage.setItem("AssetPartnerDetail", JSON.stringify(assetdetails));
      navigate("/CmsChannelDetail");
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
    // dispatch(AssetDetailAction(AssetDetail?.data))
  };
  const [sortOrders, setSortOrders] = useState({});
  useEffect(() => {
    const initialSortOrders = {
      viewKey: "asc", // Default sort order for 'filename' column
      lastactivity: "asc", // Default sort order for 'duration' column
      Programs: "asc", // Default sort order for 'dateUploaded' column
      posters: "asc", // Default sort order for 'dateUploaded' column
      promos: "asc", // Default sort order for 'dateUploaded' column
      fillers: "asc", // Default sort order for 'dateUploaded' column
      // Add more columns as needed
    };
    setSortOrders(initialSortOrders);
  }, []);

  const sortOrderEvent = async (field) => {
    let currentSortOrder = "asc"; // Default sort order

    // Check if the field was previously sorted and toggle the sort order
    if (sortOrders[field]) {
      currentSortOrder = sortOrders[field] === "asc" ? "desc" : "asc";
    }
    setSortOrders({ ...sortOrders, [field]: currentSortOrder });
  };

  const getSortIcon = (field) => {
    if (sortOrders[field] == "desc") {
      // console.log("ASss124t");
      return <img src={filter_alt} alt="desc" />; // Image for ascending order
    } else {
      // console.log("ASss",sortOrders[field],field);
      return <img src={desc} alt="Ascending" />; // Image for ascending order
    }
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    permission !== null ?
      <div className={`content-body ${(adminMode || appTheme == "dark") ? "dark" : "light"}`}>
        <div className={`dashboard-content ${(adminMode || appTheme == "dark") ? "dark" : "light"}`}>
          <div className="top-content">
            <div className="left-side">
              <h3>Asset Management</h3>
            </div>
            <div className="right-side">
              <div className="icon-list">
                <div className="search-container" style={{ width: '600px' ,  }}>
                  <input
                    id="search-input"
                    className="search-box"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                    style={{
                      width: '100%',
                    
                      height: '40px',
                      color: appTheme === "dark" ? '#ffffff' : 'black',
                      borderRadius: '12px',
                      padding: '0 10px',
                    }}
                  />
                  <button
                    className="search-button"
                    onClick={handleSearch}
                    aria-label="Search"
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      padding: '0',
                      backgroundColor: 'transparent',
                      color: appTheme === "dark" ? '#ffffff' : 'black',
                    }}
                  >
                    <img
                      src={icroundsearch}
                      alt="Search"
                      className="search-icon"
                      style={{
                        filter: appTheme === "dark" ? 'invert(100%) brightness(150%)' : 'none',
                      }}
                    />
                  </button>
                </div>




                {permission === "edit" && (
                  <div className="add-icon">
                    <a className="icon" onClick={openCreatePartnerPopup}>
                      <img src={floderaddicon} alt="" />
                    </a>
                  </div>
                )}

                <div className="view-list">
                  <a
                    className={`grid-icon icon ${activeView === "grid" ? "active" : ""}`}
                    onClick={() => handleViewChange("grid")}
                  >
                    <img src={view_module} alt="" />
                  </a>
                  <a
                    className={`grid-icon icon ${activeView === "list" ? "active" : ""}`}
                    onClick={() => handleViewChange("list")}
                  >
                    <img src={viewlist} alt="" />
                  </a>
                </div>
              </div>
            </div>



          </div>

          <div className="row">
            {activeView === "grid" ? (
              PartnerData &&
              PartnerData.map((folder, index) => (
                <div key={folder.id} className="col-xl-3 col-lg-4 col-md-6 col-12 mt-3">
                  <Card
                    onClick={(e) => {
                      if (e.nativeEvent.target.nodeName == "SPAN") {
                        return;
                      }
                      partnerdetail(folder);
                    }}
                    className={`${appTheme}`}
                  >
                    <Card.Header className={`${appTheme}`}>
                      <div className="d-flex justify-content-between partner-card">
                        <div>
                          <img className={`${appTheme}`} src={assetpart} />
                          <b className="mx-2">{folder.name}</b>
                        </div>
                        {
                          permission === "edit" ?

                            <Dropdown
                              show={isDropdownOpenArray[index]}
                              onToggle={(event) => {
                                setIsDropdownOpenArray((prevState) =>
                                  prevState.map((value, i) =>
                                    i === index ? !value : false
                                  )
                                );
                              }}
                            >
                              <Dropdown.Toggle
                                id={`dropdown-basic-${folder.id}`}
                                variant="light"
                                style={{ cursor: "pointer" }}
                                className="three-dots"
                              >
                                <span className="px-4" style={{ color: appTheme === "dark" ? '#ffffff' : 'black' }}>⋮</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openEditPartnerPopup(folder);
                                  }}
                                  eventKey="option1"
                                  onSelect={(e) =>
                                    handleDropdownSelect("option1", index)
                                  }
                                >
                                  <img src={editicon} alt="" /> Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="option2"
                                  onSelect={() =>
                                    handleDropdownSelect("option2", index)
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    partnerArchive(folder.id);
                                  }}
                                >
                                  <img src={archiveicon} alt="" /> Archive
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="option3"
                                  onSelect={() =>
                                    handleDropdownSelect("option3", index)
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    partnerDelete(folder.id);
                                  }}
                                >
                                  <img src={deleteicon} alt="" /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            : permission === "read" ?
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openEditPartnerPopup(folder);
                                }}
                                eventKey="option1"
                                onSelect={(e) =>
                                  handleDropdownSelect("option1", index)
                                }
                              >
                                <img src={viewIcon} alt="" width="18px" />
                              </div>
                              :
                              ''
                        }

                      </div>
                    </Card.Header>
                    <Card.Body >
                      <div>
                        {" "}
                        {/* Your card body content goes here */}
                        <b className=""> Programs : {folder.programsCount}</b>
                        <div className="p-0 m-0 mt-2 row">
                          <div className="col-4 px-0">
                            <div className="chip-prog chip-transcode">
                              <span>Transcoded   </span>
                              <span className="text-center " >
                                <b style={{ color: '#000' }}>{folder.transcodeCount} </b>
                              </span>
                            </div>
                          </div>
                          <div className="col-4 px-0">
                            <div className="chip-prog chip-validation">
                              <span>Validated</span>
                              <span className="text-center">
                                <b style={{ color: '#000' }}>{folder.validateCount}</b>
                              </span>
                            </div>
                          </div>
                          <div className="col-4 px-0">
                            <div className="chip-prog chip-publish">
                              <span >Published</span>
                              <span className="text-center">
                                <b style={{ color: '#000' }}> {folder.publishCount}</b>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                    <div>
                      <p className="m-1 text-center chipset">
                        <span className="chip-second1" style={{padding:10}}>
                          POSTERS :
                          <span className="  text-center ">
                            <b>{folder.postersCount? folder.postersCount : 0}</b>
                          </span>
                        </span>
                        <span className="chip-second2 "style={{padding:10}}>
                          PROMOS :
                          <span className="  text-center">
                            <b>{folder.promosCount}</b>{" "}
                          </span>
                        </span>
                        <span className="chip-second3"style={{padding:10}}>
                          FILLERS :
                          <span className="  text-center">
                            <b>{folder.fillersCount}</b>
                          </span>
                        </span>
                      </p>
                    </div>
                  </Card>
                </div>
              ))
            ) : (
              <div className="w-100">
               <div className="tab-buttons">
  <a
    onClick={() => handleTabClick(1)}
    className={`tab ${activeTab === 1 ? "active" : ""}`}
  >
    Partners
  </a>
  <a
    onClick={() => handleTabClick(2)}
    className={`tab ${activeTab === 2 ? "active" : ""}`}
  >
    Fillers
  </a>
  </div>

                <div className="my-4">
                  {activeTab === 1 && (
                    <div className="table table-checkbox">
                      <table className="listTable text-center">
                        <thead>
                          <tr>
                            <th
                              className="mx-2"
                              style={{
                                width: "25%",
                                textAlign: "left",
                                paddingLeft: "50px",
                                color: appTheme === "dark" ? '#ffffff' : 'black',
                              }}
                            >
                              PARTNER NAME
                              <a
                                className="filter-icon mx-1"

                                onClick={() => sortOrderEvent("viewKey")}
                              >
                                {getSortIcon("viewKey")}
                              </a>
                            </th>
                            <th style={{ width: "15%", textAlign: "center", color: appTheme === "dark" ? '#ffffff' : 'black', }}>
                              LAST ACTIVITY
                              <a
                                className="filter-icon mx-1"
                                onClick={() => sortOrderEvent("lastactivity")}
                              >
                                {getSortIcon("lastactivity")}
                              </a>
                            </th>
                            <th style={{ width: "30%", textAlign: "center", color: appTheme === "dark" ? '#ffffff' : 'black', }}>
                              PROGRAMS{" "}
                              <a
                                className="filter-icon mx-1"
                                onClick={() => sortOrderEvent("Programs")}
                              >
                                {getSortIcon("Programs")}
                              </a>
                            </th>
                            <th style={{ width: "10%", color: appTheme === "dark" ? '#ffffff' : 'black', }}>
                              POSTERS{" "}
                              <a
                                className="filter-icon mx-1"
                                onClick={() => sortOrderEvent("posters")}
                              >
                                {getSortIcon("posters")}
                              </a>
                            </th>
                            <th style={{ width: "10%", color: appTheme === "dark" ? '#ffffff' : 'black', }}>
                              PROMOS{" "}
                              <a
                                className="filter-icon mx-1"
                                onClick={() => sortOrderEvent("promos")}
                              >
                                {getSortIcon("promos")}
                              </a>
                            </th>
                            <th style={{ width: "12%", color: appTheme === "dark" ? '#ffffff' : 'black', }}>
                              FILLERS
                              <a
                                className="filter-icon mx-1"
                                onClick={() => sortOrderEvent("fillers")}
                              >
                                {getSortIcon("fillers")}
                              </a>
                            </th>
                            {
                              permission === "edit" ?
                                <th></th>
                                : permission === "read" ?
                                  <th>View</th>
                                  :
                                  ''
                            }
                          </tr>
                        </thead>
                        {currentData.length != 0 ? (
                          <tbody>
                            {currentData.map((row, index) => (
                              <tr
                                className={index % 2 === 0 ? "even" : "odd"}
                                key={row.id}
                                onClick={() => {
                                  partnerdetail(row);
                                }}
                              >
                                <td style={{ textAlign: "left" }}>
                                  <img
                                    className={`${appTheme}`}
                                    style={{
                                      height: "25px",
                                      filter: appTheme === "dark" ? "invert(100%) brightness(150%)" : "none",

                                    }}
                                    src={assetpart}
                                    alt=""
                                  />
                                  <span> {row?.name}</span>
                                </td>
                                <td>{DateStamp(row.updatedAt)}</td>
                                <td className="p-1 ">
                                  {row.programsCount}
                                  <div className="all-text " style={{marginTop:10, marginBottom:10}}>
                                    <span className="chip-transcode" >
                                      Transcoded:{row.transcodeCount}
                                    </span>
                                    <span className="chip-validation">
                                      Validated:
                                      {row.validateCount}
                                    </span>
                                    <span className="chip-publish">
                                      Published: {row.publishCount}
                                    </span>
                                  </div>
                                </td>
                                <td>{row?.postersCount ? row?.postersCount : 0}</td>

                                <td>{row.promosCount}</td>
                                <td>{row.fillersCount}</td>
                                {
                                  permission === "edit" ?
                                    <td onClick={(e) => e.stopPropagation()}>
                                      <Dropdown
                                        show={isDropdownOpenArray[index]}
                                        onToggle={() =>
                                          setIsDropdownOpenArray((prevState) =>
                                            prevState.map((value, i) =>
                                              i === index ? !value : false
                                            )
                                          )
                                        }
                                      >
                                        <Dropdown.Toggle
                                          id={`dropdown-basic-${row.id}`}
                                          variant="light"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span className="three-dots">⋮</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            eventKey="option1"
                                            onSelect={() =>
                                              handleDropdownSelect("option1", index)
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              openEditPartnerPopup(row);
                                            }}
                                          >
                                            <img src={editicon} alt="" /> Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey="option2"
                                            onSelect={() =>
                                              handleDropdownSelect("option2", index)
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              partnerArchive(row.id);
                                            }}
                                          >
                                            <img src={archiveicon} alt="" /> Archive
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey="option3"
                                            onSelect={() =>
                                              handleDropdownSelect("option3", index)
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              partnerDelete(row.id);
                                            }}
                                          >
                                            <img src={deleteicon} alt="" /> Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                    : permission === "read" ?
                                      <td>
                                        <a>
                                          <div
                                            eventKey="option1"
                                            onSelect={() =>
                                              handleDropdownSelect("option1", index)
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              openEditPartnerPopup(row);
                                            }}
                                          >
                                            <img src={viewIcon} alt="" width="18px" />
                                          </div>
                                        </a>
                                      </td>
                                      :
                                      ''
                                }
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tr>
                            <td>Match not found</td>
                          </tr>
                        )}
                      </table>

                      <div className="table-pagination">
                        <div className="pagination-count">
                          <div className="count">
                            {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
                              currentPage * itemsPerPage,
                              PartnerData.length
                            )} of ${PartnerData.length}`}
                          </div>
                          <div className="pagination-arrow">
                            <a
                              className="prev"
                              onClick={() =>
                                currentPage > 1 &&
                                handlePageChange(currentPage - 1)
                              }
                            >
                              <img src={leftarrow} alt="Previous" />
                            </a>
                            <a
                              className="next"
                              onClick={() =>
                                currentPage < totalPages &&
                                handlePageChange(currentPage + 1)
                              }
                            >
                              <img src={rightarrow} alt="Next" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 2 &&
                    <Filler
                      permission={permission}
                    />}
                </div>
              </div>
            )}

            <ToastContainer />

            <AddPartnerpopup
              show={showPopup}
              handleClose={closePopup}
              isEditing={createEdit === "edit"}
              partnerData={singledata}
              permission={permission}
            />
          </div>
        </div>
      </div>
      :
      ''
  );
}

export default AssetManagement;
