
import React, { useState, useEffect, useRef } from 'react';

// import "../../pages/asset-management/channeldetail/CmsChannelDetail.scss";
import '../metaData/metastyle.scss';
import Multiselect from 'multiselect-react-dropdown';
import closeChip from '../../assets/images/close-chip.svg';
import dropdownArrow from '../../assets/images/dropdown-arrow.svg';
import { Categories_list, Country_list, Genres_list, Language_list, MetadataAdd, Metadataupdate, Partner_list, Theme_list } from "../../api/api";
import { ContentGroup, image_url, ratings } from "../../service/Constant";
import video_not_available from "../../assets/images/loading.gif";
import Groupicon from "../../assets/images/Group.png";
import expand from "../../assets/images/expand.svg";
import DynamicMultiSelect, { CustomMultiSelect } from '../multiselect/MultiSelect';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { posterimage } from '../../service/commonFunc';
import check from "../../assets/images/enabled-featured.png";


import ReactHlsPlayer from 'react-hls-player';
import { cloudFront_URL } from '../../service/API_URL';







function Videoplayer({ videodata, closepartition, assetdetail, programVideos, databymeta }) {

    const AssetPartnerDetail = JSON.parse(localStorage.getItem("AssetPartnerDetail"));
    const [metadata, setMetadata] = useState(videodata?.metadata);
    const state = useSelector((state) => state);
    // const genre = state?.GenreList?.data;

    useEffect(() => {

        if (videodata?.metadata) {
            const newMetadata = videodata?.metadata;

            setMetadata(newMetadata);
            setPartnerCode(newMetadata?.partnerCode);
            setPartnerName(newMetadata?.partnerName);
            setProgramId(newMetadata?.programId);
        } else {
            setMetadata("");
            setPartnerCode("");
            setPartnerName("");

            setProgramId("");


        }


    }, [videodata]);

    const [partnerCode, setPartnerCode] = useState("");
    const [partnerName, setPartnerName] = useState("");
    const [programId, setProgramId] = useState("");
    const [hlsUrl, setHlsUrl] = useState(null);
    useEffect(() => {

        if (videodata?.publishPath != null) {
            setHlsUrl(videodata?.publishPath)
        } else {
            setHlsUrl(videodata?.sourcePath)
        }


    }, [videodata])


    // const [newUrl, setnewUrl] = useState(videodata?.sourcePath);
    const prefix = cloudFront_URL;

    // Remove the s3://dev-runn part
    const urlWithoutPrefix = hlsUrl?.replace("s3://dev-runn/", "");

    // Add the dynamic prefix
    const newUrl = `${prefix}${urlWithoutPrefix ? urlWithoutPrefix : null}`;
    //     useEffect(() => {

    //         if(getnewUrl){
    //             setnewUrl(getnewUrl)
    //         }
    // }, [getnewUrl])
    // console.log("newUrl",newUrl);

    const getFileTypeFromUrl = (url) => {

        if (url) {
            const extension = url.split('.').pop().toLowerCase();
            if (extension === 'm3u8') {
                return 'HLS';
            } else {
                return 'OTHER';
            }

        }

    };
    // let clickTimeout;

    // const handleDoubleClick = () => {
    //     // Add your double-click functionality here
    //     // For example:

    // };
    // function handleClick() {
    //     if (!clickTimeout) {
    //       // This executes on single click
    //       clickTimeout = setTimeout(() => {
    //         clickTimeout = null;
    //         closepartition();
    //       }, 200); // Adjust the delay as needed
    //     } else {
    //       // This executes on double click
    //       clearTimeout(clickTimeout);
    //       clickTimeout = null;
    //     //   handleDoubleClick();
    //     }
    //   }

    return (
        <div className=" bg-light row">
            <div className="breadcrumbs">
                <ul>
                    <li>
                        <a href='/asset'>Asset Management</a>
                    </li>
                    <li>

                        <a
                            href="/CmsChannelDetail"


                        >{AssetPartnerDetail?.name}</a>
                    </li>
                    <li className="active">Player</li>
                </ul>
            </div>

            <div className='col-10 '>
                <div className="video-container my-3">
                    <div className="video-title">{videodata?.metadata == null ? videodata?.filename : videodata?.metadata?.title}
                        <span className="small-grey-text">  {videodata?.metadata == null
                            ? null
                            : "(" + videodata?.filename + ")"}</span>



                    </div>
                    {/* {featurecheck ? <button className="image-container" style={{ marginLeft: "auto" }} onClick={() => featuredchecks()}>
                        <img
                            src={check}
                            className="image"
                        />
                    </button> :
                        <button className="image-container" style={{ marginLeft: "auto" }} onClick={() => featuredchecks()}>
                            <img
                                src={uncheck}
                                className="image"
                            />
                        </button>} */}
                    <button className="cross-container border-0" style={{ marginLeft: "auto" }}
                        //   onDoubleClickCapture={() => handleDoubleClick()}
                        onClick={() => closepartition()}
                    >
                        <img style={{ width: "12px" }}
                            src={closeChip}
                            className="image"
                        />
                    </button>


                </div>
                <div >  <div className="expand-content w-100 row bg-black p-1">
                    {/* Conditionally render HLS player for HLS files */}
                    {getFileTypeFromUrl(hlsUrl) === 'HLS' ? (
                        <ReactHlsPlayer
                            autoPlay={true}
                            src={newUrl}
                            controls={true}
                            // poster={video_not_available}
                            width="100%"
                            height="720px"
                            style={{ maxWidth: "100%", maxHeight: "56.25vw" }} // 16:9 aspect ratio
                        />

                    ) : (
                        <video className='mp4player' id='video' controls autoPlay src={newUrl} style={{ height: "720px" }}>

                        </video>
                    )}

                </div>
                </div>


            </div>
            <div className='col-2 '>
                <div className='right-bar'>
                    <div>
                        <div><h3 className='text-center right-head'> Videos </h3></div>
                        <div className='video-channel-list meta scroll-height' style={{ height: "75vh" }}>
                            {programVideos &&
                                programVideos.map((data, index) => {
                                    let image = posterimage(data)

                                    return (

                                        videodata?.filename == data?.filename ? <div className="selected-block block m-auto my-2" onClick={() => databymeta(data)} >

                                            {image == "" ?
                                                <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                                <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image} alt="" /></div>}

                                            <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}</label></div>

                                        </div> : null

                                    );
                                })}
                            {programVideos &&
                                programVideos.map((data, index) => {
                                    let image = posterimage(data)

                                    return (

                                        videodata?.filename == data?.filename ? null :

                                            <div className=" block m-auto my-2" onClick={() => databymeta(data)} >

                                                {image == "" ?
                                                    <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                                    <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image} alt="" /></div>}

                                                <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}</label></div>

                                            </div>

                                    );
                                })}

                        </div>
                    </div>


                </div>



            </div>
            {/* <div className='m-3 '>
                <button className=' default-odd' onClick={() => closepartition()}>Cancel</button>
                <button disabled={updatebtn} className=' default-even mx-3' onClick={() => handleDoneClick()}>{videodata?.metadata != null ? "Done" : "Add"}</button>


            </div> */}
        </div>
    );
}

export default Videoplayer;
